import React from 'react';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import PageTitle from 'components/common/PageTitle';
import MeinBauApiLogsTable from 'components/meinBauApiLogs/MeinBauApiLogsTable';
import { useTranslation } from 'react-i18next';
import MainBauApiLogChart from './MainBauApiLogChart';
import Tabs from 'components/common/Tabs';

export enum ApiLogsTabKeys {
  apiLogsChart = 'apiLogsChart',
  apiLogsTable = 'apiLogsTable',
}

const MeinBauApiLogsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.meinBauApiLogs') }]} />
      <PageTitle>{t('pageTitles.meinBauApiLogs')}</PageTitle>
      <Tabs
        initialTabEventKey={ApiLogsTabKeys.apiLogsChart}
        restoreLastActiveTabKey="__last_active_main_bau_api_logs_management_tab__"
        tabs={[
          {
            eventKey: ApiLogsTabKeys.apiLogsChart,
            title: t(`meinBauApiLogs.tabs.${ApiLogsTabKeys.apiLogsChart}`),
            content: <MainBauApiLogChart />,
          },
          {
            eventKey: ApiLogsTabKeys.apiLogsTable,
            title: t(`meinBauApiLogs.tabs.${ApiLogsTabKeys.apiLogsTable}`),
            content: <MeinBauApiLogsTable />,
          },
        ]}
      />
      {/* <MainBauApiLogChart />
      <MeinBauApiLogsTable /> */}
    </>
  );
};

export default MeinBauApiLogsPage;
