import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import InputParamField from 'components/common/Form/InputParamField';
import { Accordion, Col, Row } from 'react-bootstrap';
import EditParamItem from 'components/common/EditParamItem';
import ReactTable from 'components/common/Table/Table';
import { Form, Formik } from 'formik';
import { Energy } from 'graphql/types.generated';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as yup from 'yup';
import Button from 'components/common/Button';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEnergyClassProps, IEnergyClassTableRow } from './types';
import { useUpdateMultiFamilyHouseSettingsMutation } from 'graphql/mutations/iframe/generated/UpdateMultiFamilyHouseSettings';
import { toastErrorMessage, toastSuccessMessage } from 'utils/error';
import { omit } from 'lodash';

type Field = {
  energyClass: string;
  hwb: number;
  peb: number;
  co2: number;
  efficiencyFactor: number;
};

type FormValues = Record<string, Field>;

const getInitialValues = (data?: Energy[]) => {
  if (isEmpty(data)) return {};
  const result = {} as FormValues;
  data?.forEach(({ energyClass, hwb, peb, co2, efficiencyFactor }) => {
    result[energyClass] = { energyClass, hwb, peb, co2, efficiencyFactor };
  });
  return result;
};

const getSchema = (data?: Record<string, Field>) => {
  if (isEmpty(data)) return yup.object().shape({});
  const objectSchema = {} as Record<string, yup.ISchema<any>>;
  Object.keys(data).forEach((key) => {
    objectSchema[key] = yup.object({
      hwb: yup.number().required('errors.requiredField'),
      peb: yup.number().required('errors.requiredField'),
      co2: yup.number().required('errors.requiredField'),
      efficiencyFactor: yup.number().required('errors.requiredField'),
    });
  });
  return yup.object().shape(objectSchema);
};

const EnergyClass = ({ iframeId, data }: IEnergyClassProps) => {
  const { t } = useTranslation();
  const [update, { loading }] = useUpdateMultiFamilyHouseSettingsMutation({
    onCompleted: () => toastSuccessMessage(t),
    onError: toastErrorMessage,
  });
  const columns: ColumnDef<IEnergyClassTableRow>[] = [
    {
      header: t('energyCoefficients.energy.energyClass'),
      accessorKey: 'energyClass',
      cell: (info) => info.getValue(),
    },
    {
      header: t('energyCoefficients.energy.hwb'),
      accessorKey: 'hwb',
      cell: (info) => <InputParamField name={`${info.row.original.energyClass}.hwb`} />,
    },
    {
      header: t('energyCoefficients.energy.peb'),
      accessorKey: 'peb',
      cell: (info) => <InputParamField name={`${info.row.original.energyClass}.peb`} />,
    },
    {
      header: t('energyCoefficients.energy.co2'),
      accessorKey: 'co2',
      cell: (info) => <InputParamField name={`${info.row.original.energyClass}.co2`} />,
    },
    {
      header: t('energyCoefficients.energy.efficiencyFactor'),
      accessorKey: 'efficiencyFactor',
      cell: (info) => <InputParamField name={`${info.row.original.energyClass}.efficiencyFactor`} />,
    },
  ];

  const tableATData = data?.AT.map(({ energyClass }) => ({ energyClass }));
  const tableDEData = data?.DE.map(({ energyClass }) => ({ energyClass }));

  const initialValuesAT = getInitialValues(data?.AT);
  const initialValuesDE = getInitialValues(data?.DE);

  const validationSchema = getSchema(initialValuesAT);

  const onSubmitAT = async (values: FormValues) => {
    const input = Object.values(values) as Energy[];
    const energyDE = data?.DE.map((item) => omit(item, ['__typename']));
    await update({ variables: { iframeId, input: { energy: { AT: input, DE: energyDE } } } });
  };

  const onSubmitDE = async (values: FormValues) => {
    const input = Object.values(values) as Energy[];
    const energyAT = data?.AT.map((item) => omit(item, ['__typename']));
    await update({ variables: { iframeId, input: { energy: { AT: energyAT, DE: input } } } });
  };
  return (
    <Accordion>
      <EditParamItem title={t('energyCoefficients.energy.at')} eventKey="1">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmitAT}
          initialValues={initialValuesAT}
        >
          {({ values }) => (
            <Form>
              <Row className="justify-content-end mb-3">
                <Col md={2} className="text-end">
                  <Button loading={loading} type="submit" disabled={isEqual(initialValuesAT, values)}>
                    <FontAwesomeIcon icon={faSave} color="white" />
                    {t('iframe.form.update')}
                  </Button>
                </Col>
              </Row>
              <ReactTable columns={columns} data={tableATData} />
            </Form>
          )}
        </Formik>
      </EditParamItem>
      <EditParamItem title={t('energyCoefficients.energy.de')} eventKey="2">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmitDE}
          initialValues={initialValuesDE}
        >
          {({ values }) => (
            <Form>
              <Row className="justify-content-end mb-3">
                <Col md={2} className="text-end">
                  <Button loading={false} type="submit" disabled={isEqual(initialValuesDE, values)}>
                    <FontAwesomeIcon icon={faSave} color="white" />
                    {t('iframe.form.update')}
                  </Button>
                </Col>
              </Row>
              <ReactTable columns={columns} data={tableDEData} />
            </Form>
          )}
        </Formik>
      </EditParamItem>
    </Accordion>
  );
};

export default EnergyClass;
