import { useAppContext } from 'contexts/AppContext';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { NavLink, generatePath } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { routePaths } from 'router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from 'constants/theme';
import { faUser, faFileLines } from '@fortawesome/free-regular-svg-icons';
import { faBook, faBug, faCode, faFile, faFilePdf, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Sidebar as RSidebar, Menu, MenuItem, SubMenu, CSSObject } from 'react-pro-sidebar';
import s from './Sidebar.module.sass';
import NavbarBrand from 'components/common/NavbarBrand';
import { Hidden, Visible } from 'components/common/Visibility';
import Button from 'components/common/Button/Button';
import { Divide as HamburgerIcon } from 'hamburger-react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Roles } from 'graphql/types.generated';
import useUser from 'hooks/user/useUser';

enum SidebarMenuItemType {
  item = 'item',
  subMenu = 'subMenu',
}

interface SidebarItemCommonParams {
  id: string;
  getPath: ({ tenantId }: { tenantId?: string }) => string;
  title: string;
  icon: React.ReactNode;
  type: SidebarMenuItemType;
}

interface SidebarMenuItem extends SidebarItemCommonParams {
  children?: SidebarItemCommonParams[];
  requiredRoles?: Roles[];
}

const sidebarItems: SidebarMenuItem[] = [
  {
    id: 'dashboard',
    getPath: () => routePaths.dashboard,
    title: 'pageTitles.dashboard',
    icon: <DashboardIcon />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'userManagement',
    getPath: () => routePaths.userManagement,
    title: 'pageTitles.userManagement',
    icon: <FontAwesomeIcon icon={faUser} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'tenantsManagement',
    getPath: () => routePaths.tenantsManagement,
    title: 'pageTitles.tenantsManagement',
    icon: <FontAwesomeIcon icon={faLayerGroup} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'iframes',
    getPath: () => routePaths.iframesManagement,
    title: 'pageTitles.iframesManagement',
    icon: <FontAwesomeIcon icon={faFileLines} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'project-pdf-requests',
    getPath: () => routePaths.projectPdfRequests,
    title: 'pageTitles.projectPdfRequests',
    icon: <FontAwesomeIcon icon={faFilePdf} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'projects',
    getPath: () => routePaths.projects,
    title: 'pageTitles.projects',
    icon: <FontAwesomeIcon icon={faFile} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'meinbau-api-logs',
    getPath: () => routePaths.meinBauApiLogs,
    title: 'pageTitles.meinBauApiLogs',
    icon: <FontAwesomeIcon icon={faBug} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'api-call-logs',
    getPath: () => routePaths.apiCallLogs,
    title: 'pageTitles.apiCallLogs',
    icon: <FontAwesomeIcon icon={faCode} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    id: 'tenantDashboard',
    getPath: ({ tenantId }) => generatePath(routePaths.tenantDashboard, { tenantId }),
    title: 'pageTitles.dashboard',
    icon: <DashboardIcon />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    id: 'tenantIframesManagement',
    getPath: ({ tenantId }) => generatePath(routePaths.tenantIframesManagement, { tenantId }),
    title: 'pageTitles.iframesManagement',
    icon: <FontAwesomeIcon icon={faFileLines} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    id: 'tenant-meinbau-api-logs',
    getPath: ({ tenantId }) => generatePath(routePaths.tenantMeinBauApiLogs, { tenantId }),
    title: 'pageTitles.meinBauApiLogs',
    icon: <FontAwesomeIcon icon={faBug} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    id: 'meinbau-audit-logs',
    getPath: () => routePaths.auditLogs,
    title: 'pageTitles.auditLogs',
    icon: <FontAwesomeIcon icon={faBook} size="lg" />,
    type: SidebarMenuItemType.item,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
];

interface ISidebarMenuItem {
  path: string;
  title: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

const SidebarMenuItem: FC<ISidebarMenuItem> = ({ path, title, icon, onClick }) => {
  const { t } = useTranslation();
  return (
    <NavLink
      to={path}
      onClick={onClick}
      className={(navData) => (navData.isActive ? s.sidebarMenuActiveItem : s.sidebarMenuItem)}
    >
      <MenuItem component="div" icon={icon || null}>
        {t(title)}
      </MenuItem>
    </NavLink>
  );
};

const menuItemStyles = {
  subMenuContent: {
    overflow: 'visible',
  },
};
const sidebarRootStyles: CSSObject = {
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100vh',
  zIndex: theme.sidebarIndex,
  overflow: 'visible',
  transition: 'left ,right,300ms',
  ['.ps-sidebar-container']: {
    overflow: 'visible',
  },
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const { sidebarOpen, toggleSidebar } = useAppContext();

  const tenantId = useMemo(() => {
    if (me?.tenantId) return me.tenantId;
    if (!me?.activeRoles[0]) return '';
    return me.activeRoles[0].tenantId || '';
  }, [me?.activeRoles, me?.tenantId]);

  const onSideBarItemClick = useCallback(() => {
    if (isMobile) toggleSidebar(false);
  }, [toggleSidebar]);

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <RSidebar
        customBreakPoint="767px"
        width={theme.sidebarWidth}
        rootStyles={sidebarRootStyles}
        backgroundColor={theme.sidebarBgColor}
        collapsed={!sidebarOpen}
        toggled={sidebarOpen}
        // onBreakPoint={setBroken}
        onBackdropClick={() => toggleSidebar(false)}
      >
        <Menu menuItemStyles={menuItemStyles} closeOnClick>
          <Stack
            direction="horizontal"
            className="align-items-center justify-content-between ps-4 pe-2"
            style={{ height: theme.navbarHeight }}
          >
            <NavbarBrand />
            <Visible xs sm>
              <HamburgerIcon toggled={sidebarOpen} toggle={() => toggleSidebar(!sidebarOpen)} />
            </Visible>
          </Stack>

          {sidebarItems.map(({ id, type, getPath, title, icon, children = [], requiredRoles }) => {
            const role = me?.role || me?.activeRoles[0]?.role;
            if (!role || (requiredRoles?.length && !requiredRoles.includes(role))) {
              return null;
            }

            if (type === SidebarMenuItemType.subMenu) {
              return (
                <SubMenu key={id} label={t(title)} icon={icon}>
                  {children.map(({ id, getPath, title }) => {
                    const path = typeof getPath === 'function' ? getPath({ tenantId }) : '';
                    return <SidebarMenuItem key={id} {...{ title, icon, path, onClick: onSideBarItemClick }} />;
                  })}
                </SubMenu>
              );
            }

            const path = typeof getPath === 'function' ? getPath({ tenantId }) : '';
            return <SidebarMenuItem key={id} {...{ title, icon, path, onClick: onSideBarItemClick }} />;
          })}
        </Menu>
        <Hidden xs sm>
          <Button
            type="button"
            variant="light"
            className={cn(s.toggleSidebarBtn, !sidebarOpen && s.rotate180)}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        </Hidden>
      </RSidebar>
    </div>
  );
};

export default Sidebar;
