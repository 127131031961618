import React from 'react';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import PageTitle from 'components/common/PageTitle';
import MeinBauApiLogsTable from 'components/meinBauApiLogs/MeinBauApiLogsTable';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const TenantMeinBauApiLogsPage = () => {
  const { t } = useTranslation();
  const { tenantId } = useParams();

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.meinBauApiLogs') }]} />
      <PageTitle>{t('pageTitles.meinBauApiLogs')}</PageTitle>
      <MeinBauApiLogsTable tenantId={tenantId} />
    </>
  );
};

export default TenantMeinBauApiLogsPage;
