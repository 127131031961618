import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { EnergyFragmentDoc } from './EnergyFragment';
import { EnergyPricesFragmentDoc } from './EnergyPricesFragment';
export type MultiFamilyHouseSettingsFragment = {
  __typename?: 'MultiFamilyHouseSettings';
  _id: string;
  iframeId?: string | null;
  parameters: {
    __typename?: 'Parameters';
    thermalParameters: {
      __typename?: 'ThermalParameters';
      fullLoadHoursHeating: number;
      usefulEnergyHotWater: number;
      heatingSystemOutputSafetySurcharge: number;
      distributionLossForCentralHeating: number;
      annualPerformanceFactorDistrictHeating: number;
      annualPerformanceFactorGasBoiler: number;
      annualPerformanceFactorHeatPumpAir: number;
      annualPerformanceFactorGeothermalHeatPump: number;
      annualPerformanceFactorElectricHeating: number;
      annualPerformanceFactorOilHeating: number;
      annualPerformanceFactorCoalHeating: number;
      ratioNetFloorAreaToGrossFloorArea: number;
    };
    co2Footprint: { __typename?: 'Co2Footprint'; gas: number; electricity: number; districtHeating: number };
    investmentCosts: {
      __typename?: 'InvestmentCosts';
      heatUnitApartment: number;
      coolingUnitApartment: number;
      coolingUnitAreaFactor: number;
      constructionCostsApartment: number;
      electricityChangesNewStoveApartment: number;
      ascendingLinesDistributionToApartments: number;
    };
    constructionCosts: {
      __typename?: 'ConstructionCosts';
      heatPumpAir: number;
      heatPumpGeothermal: number;
      heatPumpBuildingPart: number;
      districtHeatingConnectionNetFix: number;
      districtHeatingConnectionNetVariable: number;
      buildingInstallationCosts: number;
    };
    renovationCosts: {
      __typename?: 'RenovationCosts';
      facade: number;
      windows: number;
      pvSystem: number;
      topCeilingInsulation: number;
      evChargingPoints: number;
      newFlooring: number;
      newFlooringPerUnit: number;
      newKitchen: number;
      bathroomAndToilet: number;
      wallsAndInteriorDoors: number;
      electricalInstallation: number;
      electricalInstallationPerUnit: number;
      newCarports: number;
      newGarages: number;
      gardenLandscaping: number;
      fence: number;
      fencePerUnit: number;
      domesticVentilation: number;
    };
    renovationImpact: {
      __typename?: 'RenovationImpactSettings';
      facadeReduction: number;
      windowsReduction: number;
      topCeilingInsulationReduction: number;
      domesticVentilation: number;
    };
    runningCosts: {
      __typename?: 'RunningCosts';
      heatPumpOperation: number;
      gasOperation: number;
      districtHeatingOperation: number;
      oilOperation: number;
      coalOperation: number;
      heatPumpDepreciation: number;
      gasDepreciation: number;
      districtHeatingDepreciation: number;
      oilDepreciation: number;
      coalDepreciation: number;
    };
    financing: {
      __typename?: 'Financing';
      ownFundsRatio: number;
      publicFundingRatio: number;
      interestRate: number;
      financingDuration: number;
      vacancyRateBeforeRenovation: number;
      vacancyRateAfterRenovation: number;
    };
  };
  energyStandards: Array<{
    __typename?: 'EnergyStandardSettings';
    constructionYearRange: SchemaTypes.MultiFamilyHouseEnergyStandard;
    heatingEnergyDemand: number;
    ageFactor: number;
  }>;
  energy: {
    __typename?: 'EnergyClassSettings';
    AT: Array<{
      __typename?: 'Energy';
      energyClass: string;
      hwb: number;
      peb: number;
      co2: number;
      efficiencyFactor: number;
    }>;
    DE: Array<{
      __typename?: 'Energy';
      energyClass: string;
      hwb: number;
      peb: number;
      co2: number;
      efficiencyFactor: number;
    }>;
  };
  energyPriceScenarios: {
    __typename?: 'EnergyPriceScenarios';
    costLower: {
      __typename?: 'EnergyPrices';
      gas: number;
      gasBasePrice: number;
      electricity: number;
      electricityBasePrice: number;
      oil: number;
      oilBasePrice: number;
      districtHeating: number;
      districtHeatingBasePrice: number;
      coal: number;
      coalBasePrice: number;
      co2Tax: number;
    };
    costNow: {
      __typename?: 'EnergyPrices';
      gas: number;
      gasBasePrice: number;
      electricity: number;
      electricityBasePrice: number;
      oil: number;
      oilBasePrice: number;
      districtHeating: number;
      districtHeatingBasePrice: number;
      coal: number;
      coalBasePrice: number;
      co2Tax: number;
    };
    costHigher: {
      __typename?: 'EnergyPrices';
      gas: number;
      gasBasePrice: number;
      electricity: number;
      electricityBasePrice: number;
      oil: number;
      oilBasePrice: number;
      districtHeating: number;
      districtHeatingBasePrice: number;
      coal: number;
      coalBasePrice: number;
      co2Tax: number;
    };
  };
};

export type MultiFamilyHouseSettingsFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const MultiFamilyHouseSettingsFragmentDoc = gql`
  fragment MultiFamilyHouseSettings on MultiFamilyHouseSettings {
    _id
    iframeId
    parameters {
      thermalParameters {
        fullLoadHoursHeating
        usefulEnergyHotWater
        heatingSystemOutputSafetySurcharge
        distributionLossForCentralHeating
        annualPerformanceFactorDistrictHeating
        annualPerformanceFactorGasBoiler
        annualPerformanceFactorHeatPumpAir
        annualPerformanceFactorGeothermalHeatPump
        annualPerformanceFactorElectricHeating
        annualPerformanceFactorOilHeating
        annualPerformanceFactorCoalHeating
        ratioNetFloorAreaToGrossFloorArea
      }
      co2Footprint {
        gas
        electricity
        districtHeating
      }
      investmentCosts {
        heatUnitApartment
        coolingUnitApartment
        coolingUnitAreaFactor
        constructionCostsApartment
        electricityChangesNewStoveApartment
        ascendingLinesDistributionToApartments
      }
      constructionCosts {
        heatPumpAir
        heatPumpGeothermal
        heatPumpBuildingPart
        districtHeatingConnectionNetFix
        districtHeatingConnectionNetVariable
        buildingInstallationCosts
      }
      renovationCosts {
        facade
        windows
        pvSystem
        topCeilingInsulation
        evChargingPoints
        newFlooring
        newFlooringPerUnit
        newKitchen
        bathroomAndToilet
        wallsAndInteriorDoors
        electricalInstallation
        electricalInstallationPerUnit
        newCarports
        newGarages
        gardenLandscaping
        fence
        fencePerUnit
        domesticVentilation
      }
      renovationImpact {
        facadeReduction
        windowsReduction
        topCeilingInsulationReduction
        domesticVentilation
      }
      runningCosts {
        heatPumpOperation
        gasOperation
        districtHeatingOperation
        oilOperation
        coalOperation
        heatPumpDepreciation
        gasDepreciation
        districtHeatingDepreciation
        oilDepreciation
        coalDepreciation
      }
      financing {
        ownFundsRatio
        publicFundingRatio
        interestRate
        financingDuration
        vacancyRateBeforeRenovation
        vacancyRateAfterRenovation
      }
    }
    energyStandards {
      constructionYearRange
      heatingEnergyDemand
      ageFactor
    }
    energy {
      AT {
        ...Energy
      }
      DE {
        ...Energy
      }
    }
    energyPriceScenarios {
      costLower {
        ...EnergyPrices
      }
      costNow {
        ...EnergyPrices
      }
      costHigher {
        ...EnergyPrices
      }
    }
  }
  ${EnergyFragmentDoc}
  ${EnergyPricesFragmentDoc}
`;
