import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { MultiFamilyHouseSettingsFragmentDoc } from '../../../fragments/iframe/generated/MultiFamilyHouseSettings';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMultiFamilyHouseSettingsMutationVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
  input: SchemaTypes.MultiFamilyHouseSettingsInput;
}>;

export type UpdateMultiFamilyHouseSettingsMutation = {
  __typename?: 'Mutation';
  updateMultiFamilyHouseSettings: {
    __typename?: 'MultiFamilyHouseSettings';
    _id: string;
    iframeId?: string | null;
    parameters: {
      __typename?: 'Parameters';
      thermalParameters: {
        __typename?: 'ThermalParameters';
        fullLoadHoursHeating: number;
        usefulEnergyHotWater: number;
        heatingSystemOutputSafetySurcharge: number;
        distributionLossForCentralHeating: number;
        annualPerformanceFactorDistrictHeating: number;
        annualPerformanceFactorGasBoiler: number;
        annualPerformanceFactorHeatPumpAir: number;
        annualPerformanceFactorGeothermalHeatPump: number;
        annualPerformanceFactorElectricHeating: number;
        annualPerformanceFactorOilHeating: number;
        annualPerformanceFactorCoalHeating: number;
        ratioNetFloorAreaToGrossFloorArea: number;
      };
      co2Footprint: { __typename?: 'Co2Footprint'; gas: number; electricity: number; districtHeating: number };
      investmentCosts: {
        __typename?: 'InvestmentCosts';
        heatUnitApartment: number;
        coolingUnitApartment: number;
        coolingUnitAreaFactor: number;
        constructionCostsApartment: number;
        electricityChangesNewStoveApartment: number;
        ascendingLinesDistributionToApartments: number;
      };
      constructionCosts: {
        __typename?: 'ConstructionCosts';
        heatPumpAir: number;
        heatPumpGeothermal: number;
        heatPumpBuildingPart: number;
        districtHeatingConnectionNetFix: number;
        districtHeatingConnectionNetVariable: number;
        buildingInstallationCosts: number;
      };
      renovationCosts: {
        __typename?: 'RenovationCosts';
        facade: number;
        windows: number;
        pvSystem: number;
        topCeilingInsulation: number;
        evChargingPoints: number;
        newFlooring: number;
        newFlooringPerUnit: number;
        newKitchen: number;
        bathroomAndToilet: number;
        wallsAndInteriorDoors: number;
        electricalInstallation: number;
        electricalInstallationPerUnit: number;
        newCarports: number;
        newGarages: number;
        gardenLandscaping: number;
        fence: number;
        fencePerUnit: number;
        domesticVentilation: number;
      };
      renovationImpact: {
        __typename?: 'RenovationImpactSettings';
        facadeReduction: number;
        windowsReduction: number;
        topCeilingInsulationReduction: number;
        domesticVentilation: number;
      };
      runningCosts: {
        __typename?: 'RunningCosts';
        heatPumpOperation: number;
        gasOperation: number;
        districtHeatingOperation: number;
        oilOperation: number;
        coalOperation: number;
        heatPumpDepreciation: number;
        gasDepreciation: number;
        districtHeatingDepreciation: number;
        oilDepreciation: number;
        coalDepreciation: number;
      };
      financing: {
        __typename?: 'Financing';
        ownFundsRatio: number;
        publicFundingRatio: number;
        interestRate: number;
        financingDuration: number;
        vacancyRateBeforeRenovation: number;
        vacancyRateAfterRenovation: number;
      };
    };
    energyStandards: Array<{
      __typename?: 'EnergyStandardSettings';
      constructionYearRange: SchemaTypes.MultiFamilyHouseEnergyStandard;
      heatingEnergyDemand: number;
      ageFactor: number;
    }>;
    energy: {
      __typename?: 'EnergyClassSettings';
      AT: Array<{
        __typename?: 'Energy';
        energyClass: string;
        hwb: number;
        peb: number;
        co2: number;
        efficiencyFactor: number;
      }>;
      DE: Array<{
        __typename?: 'Energy';
        energyClass: string;
        hwb: number;
        peb: number;
        co2: number;
        efficiencyFactor: number;
      }>;
    };
    energyPriceScenarios: {
      __typename?: 'EnergyPriceScenarios';
      costLower: {
        __typename?: 'EnergyPrices';
        gas: number;
        gasBasePrice: number;
        electricity: number;
        electricityBasePrice: number;
        oil: number;
        oilBasePrice: number;
        districtHeating: number;
        districtHeatingBasePrice: number;
        coal: number;
        coalBasePrice: number;
        co2Tax: number;
      };
      costNow: {
        __typename?: 'EnergyPrices';
        gas: number;
        gasBasePrice: number;
        electricity: number;
        electricityBasePrice: number;
        oil: number;
        oilBasePrice: number;
        districtHeating: number;
        districtHeatingBasePrice: number;
        coal: number;
        coalBasePrice: number;
        co2Tax: number;
      };
      costHigher: {
        __typename?: 'EnergyPrices';
        gas: number;
        gasBasePrice: number;
        electricity: number;
        electricityBasePrice: number;
        oil: number;
        oilBasePrice: number;
        districtHeating: number;
        districtHeatingBasePrice: number;
        coal: number;
        coalBasePrice: number;
        co2Tax: number;
      };
    };
  };
};

export const UpdateMultiFamilyHouseSettingsDocument = gql`
  mutation UpdateMultiFamilyHouseSettings($iframeId: ID!, $input: MultiFamilyHouseSettingsInput!) {
    updateMultiFamilyHouseSettings(iframeId: $iframeId, input: $input) {
      ...MultiFamilyHouseSettings
    }
  }
  ${MultiFamilyHouseSettingsFragmentDoc}
`;
export type UpdateMultiFamilyHouseSettingsMutationFn = Apollo.MutationFunction<
  UpdateMultiFamilyHouseSettingsMutation,
  UpdateMultiFamilyHouseSettingsMutationVariables
>;

/**
 * __useUpdateMultiFamilyHouseSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateMultiFamilyHouseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultiFamilyHouseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultiFamilyHouseSettingsMutation, { data, loading, error }] = useUpdateMultiFamilyHouseSettingsMutation({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultiFamilyHouseSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMultiFamilyHouseSettingsMutation,
    UpdateMultiFamilyHouseSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMultiFamilyHouseSettingsMutation, UpdateMultiFamilyHouseSettingsMutationVariables>(
    UpdateMultiFamilyHouseSettingsDocument,
    options,
  );
}
export type UpdateMultiFamilyHouseSettingsMutationHookResult = ReturnType<
  typeof useUpdateMultiFamilyHouseSettingsMutation
>;
export type UpdateMultiFamilyHouseSettingsMutationResult =
  Apollo.MutationResult<UpdateMultiFamilyHouseSettingsMutation>;
export type UpdateMultiFamilyHouseSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMultiFamilyHouseSettingsMutation,
  UpdateMultiFamilyHouseSettingsMutationVariables
>;
