import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import Tabs from 'components/common/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { routePaths } from 'router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import useTenant from 'hooks/tenant/useTenant';
import TenantBaseData from 'components/tenants/TenantBaseData';
import { Alert, Placeholder } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import UserManagement from 'components/userManagement/UserManagement';
import { AccountType, Roles } from 'graphql/types.generated';
import { TenantQuery } from 'graphql/queries/tenant/generated/Tenant';
import Loading from 'components/common/Loading/Loading';
import TenantIframes from 'components/tenants/TenantIframes';
import useUser from 'hooks/user/useUser';

enum TabKeys {
  baseData = 'baseData',
  iframes = 'iframes',
  users = 'users',
}

const TenantPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const { tenant, error, loading } = useTenant({ tenantId: tenantId as string });
  const { me } = useUser({ fetchPolicy: 'cache-only' });

  return (
    <>
      <BreadcrumbBuilder
        pathLinks={[
          {
            text: t('pageTitles.tenantsManagement'),
            onClick: () => navigate(routePaths.tenantsManagement),
            isHide: me?.role !== Roles.MEINBAU_ADMIN,
          },
          {
            style: { minWidth: '250px' },
            text: (
              <>
                {loading && !tenant ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={11} />
                  </Placeholder>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faBuilding} /> {(tenant as TenantQuery['tenant']).companyName}
                  </>
                )}
              </>
            ),
          },
        ]}
      />
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && tenant ? (
        <>
          <PageTitle>{(tenant as TenantQuery['tenant']).companyName}</PageTitle>
          <Tabs
            initialTabEventKey={TabKeys.baseData}
            restoreLastActiveTabKey="__last_active_tenant_management_tab__"
            tabs={[
              {
                eventKey: TabKeys.baseData,
                title: t('tenantManagement.tabs.baseData'),
                content: <TenantBaseData tenant={tenant as TenantQuery['tenant']} />,
              },
              {
                eventKey: TabKeys.iframes,
                title: t('tenantManagement.tabs.iframes'),
                content: <TenantIframes tenantId={tenant._id} />,
              },
              ...(me?.role === Roles.MEINBAU_ADMIN
                ? [
                    {
                      eventKey: TabKeys.users,
                      title: t('tenantManagement.tabs.users'),
                      content: <UserManagement accountType={AccountType.tenant} />,
                    },
                  ]
                : []),
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export default TenantPage;
