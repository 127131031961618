import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeinBauApiLogsCountQueryVariables = SchemaTypes.Exact<{
  rangeEnd?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
  rangeStart?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
}>;

export type MeinBauApiLogsCountQuery = {
  __typename?: 'Query';
  getMeinBauApiLogsCount: Array<{
    __typename?: 'MeinBauApiLogPerTenant';
    _id: string;
    tenantId: string;
    companyName: string;
    count: number;
  }>;
};

export const MeinBauApiLogsCountDocument = gql`
  query MeinBauApiLogsCount($rangeEnd: DateTimeISO, $rangeStart: DateTimeISO) {
    getMeinBauApiLogsCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      _id
      tenantId
      companyName
      count
    }
  }
`;

/**
 * __useMeinBauApiLogsCountQuery__
 *
 * To run a query within a React component, call `useMeinBauApiLogsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeinBauApiLogsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeinBauApiLogsCountQuery({
 *   variables: {
 *      rangeEnd: // value for 'rangeEnd'
 *      rangeStart: // value for 'rangeStart'
 *   },
 * });
 */
export function useMeinBauApiLogsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<MeinBauApiLogsCountQuery, MeinBauApiLogsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeinBauApiLogsCountQuery, MeinBauApiLogsCountQueryVariables>(
    MeinBauApiLogsCountDocument,
    options,
  );
}
export function useMeinBauApiLogsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeinBauApiLogsCountQuery, MeinBauApiLogsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeinBauApiLogsCountQuery, MeinBauApiLogsCountQueryVariables>(
    MeinBauApiLogsCountDocument,
    options,
  );
}
export type MeinBauApiLogsCountQueryHookResult = ReturnType<typeof useMeinBauApiLogsCountQuery>;
export type MeinBauApiLogsCountLazyQueryHookResult = ReturnType<typeof useMeinBauApiLogsCountLazyQuery>;
export type MeinBauApiLogsCountQueryResult = Apollo.QueryResult<
  MeinBauApiLogsCountQuery,
  MeinBauApiLogsCountQueryVariables
>;
export function refetchMeinBauApiLogsCountQuery(variables?: MeinBauApiLogsCountQueryVariables) {
  return { query: MeinBauApiLogsCountDocument, variables: variables };
}
