import React from 'react';
import { toastErrorMessage } from 'utils/error';
import Loading from 'components/common/Loading/Loading';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ThermalParameters from './ThermalParameters';
import { useMultiFamilyHouseSettingsQuery } from 'graphql/queries/multiFamilyHouse/generated/MultiFamilyHouseSettings';
import Co2Footprint from './Co2Footprint';
import InvestmentCost from './InvestmentCost';
import ConstructionCosts from './ConstructionCosts';
import RenovationCosts from './RenovationCosts';
import RenovationImpact from './RenovationImpact';
import RunningCosts from './RunningCosts';
import Financing from './Financing';
import EditParamItem from 'components/common/EditParamItem';

const Parameters = ({ iframeId }: { iframeId: string }) => {
  const { data, loading } = useMultiFamilyHouseSettingsQuery({
    variables: { iframeId },
    onError: toastErrorMessage,
  });

  const { t } = useTranslation();

  if (loading) return <Loading />;
  return (
    <Accordion>
      <EditParamItem title={t('parameters.thermalParameters.title')} eventKey="1">
        <ThermalParameters data={data?.multiFamilyHouseSettings.parameters.thermalParameters} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.co2Footprint.title')} eventKey="2">
        <Co2Footprint data={data?.multiFamilyHouseSettings.parameters.co2Footprint} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.investmentCost.title')} eventKey="3">
        <InvestmentCost data={data?.multiFamilyHouseSettings.parameters.investmentCosts} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.constructionCosts.title')} eventKey="4">
        <ConstructionCosts data={data?.multiFamilyHouseSettings.parameters.constructionCosts} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.renovationCosts.title')} eventKey="5">
        <RenovationCosts data={data?.multiFamilyHouseSettings.parameters.renovationCosts} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.renovationImpact.title')} eventKey="6">
        <RenovationImpact data={data?.multiFamilyHouseSettings.parameters.renovationImpact} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.runningCosts.title')} eventKey="7">
        <RunningCosts data={data?.multiFamilyHouseSettings.parameters.runningCosts} iframeId={iframeId} />
      </EditParamItem>
      <EditParamItem title={t('parameters.financing.title')} eventKey="8">
        <Financing data={data?.multiFamilyHouseSettings.parameters.financing} iframeId={iframeId} />
      </EditParamItem>
    </Accordion>
  );
};

export default Parameters;
