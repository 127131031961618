import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import { InitialValues } from './types';

export const getValidationSchema = (values: InitialValues) => {
  if (isEmpty(values)) return yup.object().shape({});
  const objectSchema = {} as Record<keyof InitialValues, yup.ISchema<any>>;
  Object.keys(values).forEach((key) => {
    const typedKey = key as keyof InitialValues;
    objectSchema[typedKey] = yup.number().required('errors.requiredField');
  });
  return yup.object().shape(objectSchema);
};
