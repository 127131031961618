import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type EnergyPricesFragment = {
  __typename?: 'EnergyPrices';
  gas: number;
  gasBasePrice: number;
  electricity: number;
  electricityBasePrice: number;
  oil: number;
  oilBasePrice: number;
  districtHeating: number;
  districtHeatingBasePrice: number;
  coal: number;
  coalBasePrice: number;
  co2Tax: number;
};

export type EnergyPricesFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const EnergyPricesFragmentDoc = gql`
  fragment EnergyPrices on EnergyPrices {
    gas
    gasBasePrice
    electricity
    electricityBasePrice
    oil
    oilBasePrice
    districtHeating
    districtHeatingBasePrice
    coal
    coalBasePrice
    co2Tax
  }
`;
