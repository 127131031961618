import React from 'react';
import Loading from 'components/common/Loading/Loading';
import { useMultiFamilyHouseSettingsQuery } from 'graphql/queries/multiFamilyHouse/generated/MultiFamilyHouseSettings';
import { Accordion } from 'react-bootstrap';
import { toastErrorMessage } from 'utils/error';
import { useTranslation } from 'react-i18next';
import EditParamItem from 'components/common/EditParamItem';
import EnergyStandard from './EnergyStandard';
import EnergyClass from './EnergyClass';
import EnergyPriceScenarios from './EnergyPriceScenarios';

const EnergyCoefficients = ({ iframeId }: { iframeId: string }) => {
  const { data, loading } = useMultiFamilyHouseSettingsQuery({
    variables: { iframeId },
    onError: toastErrorMessage,
  });

  const { t } = useTranslation();

  if (loading) return <Loading />;
  return (
    <Accordion>
      <EditParamItem title={t('energyCoefficients.energyStandard.title')} eventKey="1">
        <EnergyStandard iframeId={iframeId} data={data?.multiFamilyHouseSettings.energyStandards} />
      </EditParamItem>
      <EditParamItem title={t('energyCoefficients.energy.title')} eventKey="2">
        <EnergyClass iframeId={iframeId} data={data?.multiFamilyHouseSettings.energy} />
      </EditParamItem>
      <EditParamItem title={t('energyCoefficients.energyPriceScenarios.title')} eventKey="3">
        <EnergyPriceScenarios iframeId={iframeId} data={data?.multiFamilyHouseSettings.energyPriceScenarios} />
      </EditParamItem>
    </Accordion>
  );
};

export default EnergyCoefficients;
