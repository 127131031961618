import React, { useCallback, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { subTimezoneOffset, formatDate } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import SimpleDatePicker from 'components/inputs/DatePicker/SimpleDatePicker';
import { endOfDay } from 'date-fns';
import Loading from 'components/common/Loading/Loading';

export enum TimePeriod {
  allTime = 'allTime',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  custom = 'custom',
}

interface IAnalyticRangePickerProp {
  callbackQuery: (rangeStart?: Date, rangeEnd?: Date) => Promise<void>;
  tenantId?: string;
  loading?: boolean;
}

export const calculateDateRange = (timePeriod: TimePeriod) => {
  const currentDate = new Date();
  switch (timePeriod) {
    case TimePeriod.thisMonth:
      return {
        startDate: subTimezoneOffset(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endDate: subTimezoneOffset(endOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))),
      };
    case TimePeriod.lastMonth:
      return {
        startDate: subTimezoneOffset(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)),
        endDate: subTimezoneOffset(endOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0))),
      };
    default:
      return null;
  }
};

interface IRangeButtonProps {
  timePeriod: TimePeriod;
  loading?: boolean;
  title: string;
  onTimePeriodChange: (timePeriod: TimePeriod) => Promise<void>;
  activeTimePeriod: TimePeriod;
}

const RangeButton = ({ title, activeTimePeriod, loading, timePeriod, onTimePeriodChange }: IRangeButtonProps) => {
  return (
    <Button
      className="d-flex justify-content-center"
      disabled={loading}
      onClick={() => onTimePeriodChange(timePeriod)}
      active={timePeriod === activeTimePeriod}
    >
      {loading ? <Loading color="white" size={30} /> : title}
    </Button>
  );
};

const AnalyticRangePicker: FC<IAnalyticRangePickerProp> = ({ callbackQuery, loading }) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [datePickerStartDate, setDatePickerStartDate] = useState<Date | undefined>();
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | undefined>();
  const [timePeriod, setTimePeriod] = useState(TimePeriod.allTime);

  const isValidDate = (d?: Date | Date[]) => {
    return d instanceof Date && !isNaN(d.getSeconds());
  };

  const setDate = (setStateCallback: any, date?: Date | Date[]) => {
    if (isValidDate(date as Date)) {
      setStateCallback(new Date(date as Date));
    } else {
      setStateCallback(undefined);
    }
  };

  const onTimePeriodChange = useCallback(
    async (timePeriod: TimePeriod) => {
      let startDate: Date | undefined;
      let endDate: Date | undefined;

      if (timePeriod === TimePeriod.custom) {
        startDate = datePickerStartDate;
        endDate = datePickerEndDate;
      } else {
        const calculatedDates = calculateDateRange(timePeriod);
        startDate = calculatedDates?.startDate;
        endDate = calculatedDates?.endDate;
      }

      setStartDate(startDate);
      setEndDate(endDate);
      setTimePeriod(timePeriod);

      if (typeof callbackQuery === 'function') await callbackQuery(startDate, endDate);
    },
    [callbackQuery, datePickerEndDate, datePickerStartDate],
  );

  return (
    <Row className="justify-content-center h-90">
      <Row className="pb-3">
        <span className="justify-content-center text-primary">
          {startDate
            ? formatDate({ date: startDate, dateFormat: 'dd MMMM yyyy' })
            : t('dashboardInfo.rangePicker.startDate')}
          -
          {endDate ? formatDate({ date: endDate, dateFormat: 'dd MMMM yyyy' }) : t('dashboardInfo.rangePicker.endDate')}
        </span>
      </Row>
      <Row className="pb-3 px-4">
        <RangeButton
          onTimePeriodChange={onTimePeriodChange}
          activeTimePeriod={timePeriod}
          timePeriod={TimePeriod.lastMonth}
          loading={loading}
          title={t('dashboardInfo.rangePicker.lastMonthDateRange')}
        />
      </Row>
      <Row className="pb-3 px-4">
        <RangeButton
          onTimePeriodChange={onTimePeriodChange}
          activeTimePeriod={timePeriod}
          timePeriod={TimePeriod.thisMonth}
          loading={loading}
          title={t('dashboardInfo.rangePicker.thisMonthDateRange')}
        />
      </Row>
      <Row className="pb-3 px-4">
        <RangeButton
          onTimePeriodChange={onTimePeriodChange}
          activeTimePeriod={timePeriod}
          timePeriod={TimePeriod.allTime}
          loading={loading}
          title={t('dashboardInfo.rangePicker.allTimeDateRange')}
        />
      </Row>

      <Row className="pb-3">
        <span className="text-primary pb-2">{t('dashboardInfo.rangePicker.startTimeDateRange')}</span>
        <SimpleDatePicker
          disabled={loading}
          selected={datePickerStartDate}
          onChange={(date) => setDate(setDatePickerStartDate, date)}
        />
      </Row>
      <Row className="pb-3">
        <span className="text-primary  pb-2">{t('dashboardInfo.rangePicker.endTimeDateRange')}</span>
        <SimpleDatePicker
          disabled={loading}
          selected={datePickerEndDate}
          onChange={(date) => setDate(setDatePickerEndDate, date)}
        />
      </Row>
      <Row className="pb-3 px-4">
        <RangeButton
          onTimePeriodChange={onTimePeriodChange}
          activeTimePeriod={timePeriod}
          timePeriod={TimePeriod.custom}
          loading={loading}
          title={t('dashboardInfo.rangePicker.applyRange')}
        />
      </Row>
    </Row>
  );
};

export default AnalyticRangePicker;
