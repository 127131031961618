import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactTable from 'components/common/Table/Table';
import InputParamField from 'components/common/Form/InputParamField';
import { ColumnDef } from '@tanstack/react-table';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import isEqual from 'lodash/isEqual';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { IEnergyPriceScenariosProps, IEnergyPriceScenarioTableRow } from './types';
import { toastErrorMessage, toastSuccessMessage } from 'utils/error';
import { useUpdateMultiFamilyHouseSettingsMutation } from 'graphql/mutations/iframe/generated/UpdateMultiFamilyHouseSettings';

type FormField = {
  co2Tax: number;
  coal: number;
  coalBasePrice: number;
  districtHeating: number;
  districtHeatingBasePrice: number;
  electricity: number;
  electricityBasePrice: number;
  gas: number;
  gasBasePrice: number;
  oil: number;
  oilBasePrice: number;
};

type FormValues = {
  costHigher: FormField;
  costLower: FormField;
  costNow: FormField;
};

const getInitialValues = (data: IEnergyPriceScenariosProps['data']) => {
  const result = omit(data, ['__typename', 'costLower.__typename', 'costNow.__typename', 'costHigher.__typename']);
  return result as FormValues;
};

const getSchema = (values?: FormValues) => {
  if (isEmpty(values)) return yup.object().shape({});
  const objectSchema = {} as Record<keyof FormValues, yup.ISchema<any>>;
  const fieldSchema = {} as Record<keyof FormField, yup.ISchema<any>>;
  Object.keys(values.costHigher).forEach((key) => {
    const typedKey = key as keyof FormField;
    fieldSchema[typedKey] = yup.number().required('errors.requiredField');
  });
  Object.keys(values).forEach((key) => {
    const typedKey = key as keyof FormValues;
    objectSchema[typedKey] = yup.object(fieldSchema);
  });
  return yup.object().shape(objectSchema);
};

const EnergyPriceScenarios = ({ iframeId, data }: IEnergyPriceScenariosProps) => {
  const { t } = useTranslation();
  const [update, { loading }] = useUpdateMultiFamilyHouseSettingsMutation({
    onCompleted: () => toastSuccessMessage(t),
    onError: toastErrorMessage,
  });
  const columns: ColumnDef<IEnergyPriceScenarioTableRow>[] = [
    {
      header: t('energyCoefficients.energyPriceScenarios.energyType'),
      accessorKey: 'name',
      cell: (info) => t(`energyCoefficients.energyPriceScenarios.${info.getValue()}`),
    },
    {
      header: t('energyCoefficients.energyPriceScenarios.costLower'),
      accessorKey: 'costLower',
      cell: (info) => <InputParamField name={`costLower.${info.row.original.name}`} />,
    },
    {
      header: t('energyCoefficients.energyPriceScenarios.costNow'),
      accessorKey: 'costNow',
      cell: (info) => <InputParamField name={`costNow.${info.row.original.name}`} />,
    },
    {
      header: t('energyCoefficients.energyPriceScenarios.costHigher'),
      accessorKey: 'costHigher',
      cell: (info) => <InputParamField name={`costHigher.${info.row.original.name}`} />,
    },
    {
      header: t('energyCoefficients.energyPriceScenarios.unit'),
      accessorKey: 'unit',
      cell: (info) => t(`energyCoefficients.energyPriceScenarios.units.${info.row.original.name}`),
    },
  ];
  const tableData = Object.keys(omit(data?.costNow, ['__typename']) || {}).map((key) => ({ name: key }));

  const onSubmit = async (values: FormValues) => {
    await update({ variables: { iframeId, input: { energyPriceScenarios: values } } });
  };
  const initialValues = getInitialValues(data);
  const validationSchema = getSchema(initialValues);

  return (
    <Formik validationSchema={validationSchema} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <Row className="justify-content-end mb-3">
            <Col md={2} className="text-end">
              <Button loading={loading} type="submit" disabled={isEqual(initialValues, values)}>
                <FontAwesomeIcon icon={faSave} color="white" />
                {t('iframe.form.update')}
              </Button>
            </Col>
          </Row>
          <ReactTable columns={columns} data={tableData} />
        </Form>
      )}
    </Formik>
  );
};

export default EnergyPriceScenarios;
