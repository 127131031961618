import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type EnergyFragment = {
  __typename?: 'Energy';
  energyClass: string;
  hwb: number;
  peb: number;
  co2: number;
  efficiencyFactor: number;
};

export type EnergyFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const EnergyFragmentDoc = gql`
  fragment Energy on Energy {
    energyClass
    hwb
    peb
    co2
    efficiencyFactor
  }
`;
