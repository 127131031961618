import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

const EditParamItem = ({
  title,
  children,
  eventKey,
}: {
  eventKey: string;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <Row>
          <Col md={1}>
            <FontAwesomeIcon icon={faEdit} color="#3367d6" />
          </Col>
          <Col>{title}</Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
};

export default EditParamItem;
