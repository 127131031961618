import CardWithHeader from 'components/common/CardWithHeader';
import TextInput from 'components/inputs/TextInput';
import { FastField, useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TInitialValues } from './IframeForm.types';
import { ProjectType } from 'graphql/types.generated';
import Checkbox from 'components/inputs/Checkbox';
import TextArea from 'components/inputs/TextArea';
import { useTranslation } from 'react-i18next';

const PdfConfigSection = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<TInitialValues>();

  return (
    <CardWithHeader className="mb-3" title={t('iframe.form.pdfConfig')}>
      <Row>
        <Col md={6}>
          <FastField
            name="requestProjectPdfConfig.emailFrom"
            component={TextInput}
            label="iframe.form.pdfEmailConfigEmail"
          />
        </Col>
        <Col md={6}>
          <FastField name="contactEmail" component={TextInput} label="iframe.form.contactEmail" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FastField
            name="requestProjectPdfConfig.ccEmail"
            component={TextInput}
            label="iframe.form.pdfEmailConfigEmailCC"
          />
        </Col>
      </Row>
      {values.projectType === ProjectType.renovatingHouse ? (
        <Row>
          <Col md={6}>
            <FastField
              name="requestProjectPdfConfig.useImplementationStartPoint"
              component={Checkbox}
              label="iframe.form.useImplementationStartPoint"
            />
          </Col>
          <Col md={6}>
            <FastField
              name="requestProjectPdfConfig.usePhoneNumber"
              component={Checkbox}
              label="iframe.form.usePhoneNumber"
            />
          </Col>
        </Row>
      ) : null}
      <FastField
        name="requestProjectPdfConfig.subject"
        component={TextInput}
        label="iframe.form.pdfEmailConfigSubject"
      />

      <FastField
        name="requestProjectPdfConfig.message"
        component={TextArea}
        label="iframe.form.pdfEmailConfigMessage"
      />

      <FastField name="projectPdfConfig.pdfWebsiteUrl" component={TextInput} label="iframe.form.pdfWebsiteUrl" />
      <FastField
        name="requestProjectPdfConfig.newPdfRequestNotification"
        component={Checkbox}
        label="iframe.form.newPdfRequestNotification"
      />
      {values.projectType === ProjectType.renovatingHouse ? (
        <FastField
          name="projectPdfConfig.pdfDownloadEnabledViaPublicRoute"
          component={Checkbox}
          label="iframe.form.pdfDownloadEnabledViaPublicRoute"
        />
      ) : null}
    </CardWithHeader>
  );
};

export default PdfConfigSection;
