import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import ReactTable from 'components/common/Table/Table';
import { Form, Formik } from 'formik';
import { EnergyStandardSettingsInput, MultiFamilyHouseEnergyStandard } from 'graphql/types.generated';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/common/Button';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import map from 'lodash/map';
import { useUpdateMultiFamilyHouseSettingsMutation } from 'graphql/mutations/iframe/generated/UpdateMultiFamilyHouseSettings';
import { toastErrorMessage, toastSuccessMessage } from 'utils/error';
import InputField from '../../common/Form/InputParamField';
import { IEnergyStandardProps, IEnergyStandardTableRow } from './types';

type Field = {
  ageFactor: number;
  heatingEnergyDemand: number;
};

const getInitialValues = (data: IEnergyStandardProps['data']) => {
  const result = {} as Record<MultiFamilyHouseEnergyStandard, Field>;
  data?.forEach(({ constructionYearRange, ageFactor, heatingEnergyDemand }) => {
    result[constructionYearRange] = { ageFactor, heatingEnergyDemand };
  });
  return result;
};

const getSchema = () => {
  const objectSchema = {} as Record<keyof MultiFamilyHouseEnergyStandard, yup.ISchema<any>>;
  Object.keys(MultiFamilyHouseEnergyStandard).forEach((key) => {
    const typedKey = key as keyof MultiFamilyHouseEnergyStandard;
    objectSchema[typedKey] = yup.object({
      ageFactor: yup.number().required('errors.requiredField'),
      heatingEnergyDemand: yup.number().required('errors.requiredField'),
    });
  });
  return yup.object().shape(objectSchema);
};

type FormValues = Record<MultiFamilyHouseEnergyStandard, Field>;

const EnergyStandard = ({ iframeId, data }: IEnergyStandardProps) => {
  const { t } = useTranslation();
  const [update, { loading }] = useUpdateMultiFamilyHouseSettingsMutation({
    onCompleted: () => toastSuccessMessage(t),
    onError: toastErrorMessage,
  });
  const columns: ColumnDef<IEnergyStandardTableRow>[] = [
    {
      header: t('energyCoefficients.energyStandard.constructionYear'),
      accessorKey: 'constructionYearRange',
      cell: (info) => t(`constructionYears.${info.getValue()}`),
    },
    {
      header: t('energyCoefficients.energyStandard.heatingEnergyDemand'),
      accessorKey: 'heatingEnergyDemand',
      cell: (info) => <InputField name={`${info.row.original.constructionYearRange}.heatingEnergyDemand`} />,
    },
    {
      header: t('energyCoefficients.energyStandard.ageFactor'),
      accessorKey: 'ageFactor',
      cell: (info) => <InputField name={`${info.row.original.constructionYearRange}.ageFactor`} />,
    },
    {
      header: t('energyCoefficients.energyStandard.description'),
      accessorKey: 'description',
      cell: (info) => t(`energyCoefficients.energyStandard.descriptions.${info.row.original.constructionYearRange}`),
    },
  ];

  const tableData = data?.map(({ constructionYearRange }) => ({ constructionYearRange }));

  const initialValues = getInitialValues(data);

  const validationSchema = getSchema();
  const onSubmit = async (values: FormValues) => {
    const input = map(values, (value, key) => ({
      ...value,
      constructionYearRange: key as MultiFamilyHouseEnergyStandard,
    })) as EnergyStandardSettingsInput[];

    await update({ variables: { iframeId, input: { energyStandards: input } } });
  };
  return (
    <Formik {...{ onSubmit, initialValues, validationSchema, enableReinitialize: true }}>
      {({ values }) => (
        <Form>
          <Row className="justify-content-end mb-3">
            <Col md={2} className="text-end">
              <Button loading={loading} type="submit" disabled={isEqual(initialValues, values)}>
                <FontAwesomeIcon icon={faSave} color="white" />
                {t('iframe.form.update')}
              </Button>
            </Col>
          </Row>
          <ReactTable columns={columns} data={tableData} />
        </Form>
      )}
    </Formik>
  );
};

export default EnergyStandard;
