import React from 'react';
import AnalyticRangePicker from 'components/dashboard/AnalyticRangePicker';
import CountPerEntityChart from 'components/dashboard/CountPerEntityChart';
import { useMeinBauApiLogsCountQuery } from 'graphql/queries/meinBauApiLogs/generated/MeinBauApiLogsCount';
import { CountEntitiesPerTenant } from 'graphql/types.generated';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { grabErrorMessage, toastErrorMessage } from 'utils/error';
import isEmpty from 'lodash/isEmpty';
import LoadingOverlay from 'components/common/Loading/LoadingOverlay';

const MainBauApiLogChart = () => {
  const { data, loading, error, refetch } = useMeinBauApiLogsCountQuery();

  const onDateRangeChanged = React.useCallback(
    async (rangeStart?: Date, rangeEnd?: Date) => {
      try {
        refetch({ rangeStart, rangeEnd });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [refetch],
  );

  const { t } = useTranslation();

  if (loading && isEmpty(data)) return <LoadingOverlay />;
  return (
    <div>
      <Card className="mb-3">
        <Card.Body>
          {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
          <Row>
            <Col xs={10} xl={2}>
              <AnalyticRangePicker loading={loading} callbackQuery={onDateRangeChanged} />
            </Col>
            <Col xs={12} xl={{ span: 8, offset: 1 }}>
              <CountPerEntityChart
                name={t('dashboardInfo.projectPerTenant')}
                data={data?.getMeinBauApiLogsCount as CountEntitiesPerTenant[]}
                noDataText={{
                  title: t('dashboardInfo.noDataTextProjects.title'),
                  description: t('dashboardInfo.noDataTextProjects.description'),
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainBauApiLogChart;
